<template>
  <div>
    <div class="box">
      <input
        v-model="nickname"
        placeholder="pseudo"
        type="text"
        required="required"
        class="avaInput"
      />
      <input
        v-model="sessionId"
        placeholder="code de session"
        type="text"
        required="required"
        class="avaInput"
      />
      <button @click="join" class="button">Rejoindre</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sessionState: Object,
    clientState: Object,
  },
  data() {
    return {
      nickname: '',
      sessionId: '',
    };
  },
  methods: {
    join() {
      this.clientState.nickname = this.nickname.toUpperCase().trim();
      this.clientState.sessionId = this.sessionId.toUpperCase().trim();
      localStorage.setItem('avalia.engage.nickname', this.clientState.nickname);
      localStorage.setItem('avalia.engage.sessionId', this.clientState.sessionId);
      this.$socket.emit('joinSession', {
        nickname: this.nickname.toUpperCase().trim(),
        sessionId: this.sessionId.toUpperCase().trim(),
      });
    },
  },
  mounted() {
    const storedNickname = localStorage.getItem('avalia.engage.nickname');
    const storedSessionId = localStorage.getItem('avalia.engage.sessionId');
    if (storedNickname) {
      this.nickname = storedNickname;
    }
    if (storedSessionId) {
      this.sessionId = storedSessionId;
    }
  },
};
</script>

<style lang="scss" scoped></style>>
