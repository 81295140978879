<template>
  <div v-show="shouldDisplayResource" class="box">
    <div v-show="false" v-html="logs" class="logs">
    </div>
    <div class="resource">
      <div v-html="resourceDescription"></div>
    </div>
    <div v-show="resourceThumbnail !== null" class="resource">
      <img
        data-v-7bfd53bd=""
        :src="resourceThumbnail"
        alt="Aperçu image de la ressource"
        class="cursor-pointer"
      />
    </div>
    <div class="resource">
      <audio v-show="isAudio" ref="player" controls="controls">
        <source
          v-bind:src="resourceFile"
          type="audio/mpeg"
        />
        Your browser does not support the audio element.
      </audio>
    </div>
    <!--
    <a target="_blank" href="https://ressources.ciip.ch/api/files/96722">Lire le document</a>
    -->
  </div>
</template>

<script>
export default {
  props: {
    isConnected: Boolean,
    sessionState: Object,
    clientState: Object,
  },
  data() {
    return {
      ciipDigitalResource: undefined,
      logs: 'Logs:',
    };
  },
  computed: {
    ciipDigitalResourceId() {
      return this.sessionState.activity?.ciipDigitalResourceId;
    },
    resourceDescription() {
      return `${this.ciipDigitalResource?.title}`; // : ${this.ciipDigitalResource?.description}`;
    },
    isAudio() {
      if (!this.ciipDigitalResource?.files || this.ciipDigitalResource.files.length === 0) {
        return false;
      }
      return this.ciipDigitalResource.files[0].type.startsWith('audio');
    },
    resourceThumbnail() {
      if (this.ciipDigitalResource?.thumbnail) {
        return `https://ressources.ciip.ch/api${this.ciipDigitalResource?.thumbnail?.href}`;
      }
      return null; // 'https://portail.ciip.ch/img/placeholder.png';
    },
    resourceFile() {
      if (this.ciipDigitalResource?.files?.length > 0) {
        return `https://ressources.ciip.ch/api${this.ciipDigitalResource?.files[0]?.href}`;
      }
      return 'no url';
    },
    shouldDisplayResource() {
      return this.sessionState?.activity?.ciipDigitalResourceId && this.sessionState?.activity?.state === 'SHOW';
    },
  },
  methods: {
    updateDisplay() {
      console.log('update display');
      console.log(this.ciipDigitalResourceId);
      if (!this.ciipDigitalResourceId) {
        return;
      }
      this.logs += `</br>updateDisplay: ${this.ciipDigitalResourceId}`;
      fetch(`https://ressources.ciip.ch/api/resources/${this.ciipDigitalResourceId}`)
        .then(response => response.json())
        .then((data) => {
          console.log(data);
          this.logs += `</br>data: ${data}`;
          this.ciipDigitalResource = data;
          this.$refs.player.load();
          this.logs += '</br>after load';
        })
        .catch((e) => {
          console.log(e);
          this.logs += `<br/> ${e}`;
        });
    },
  },
  sockets: {
    sessionState() {
      // this.updateDisplay();
    },
  },
  watch: {
    ciipDigitalResourceId() {
      this.updateDisplay();
    },
  },
  mounted() {
    this.updateDisplay();
  },
};
</script>

<style lang="scss" scoped>
.resource {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: white;

  img {
    max-width: 80%;
  }
}
</style>
