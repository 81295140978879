<template>
  <v-card width="500" class="mx-auto">
    <v-toolbar color="pink" dark>
      <!--
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      -->
      <v-toolbar-title>Engage your audience with these questions</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn @click="switchState" icon>
        <v-icon>{{ stateIcon }}</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list>
      <v-list-group
        v-for="(question, index) in stateDependentQuestions"
        :key="index"
        v-model="question.active"
        prepend-icon="mdi-comment-question-outline"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="question.text"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-show="state === 'paused'">
            <v-btn icon @click.stop="editQuestion(index)">
              <v-icon color="green lighten-1">mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-show="state === 'paused'">
            <v-btn icon @click.stop="deleteQuestion(index)">
              <v-icon color="red lighten-1">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-show="state === 'playing'">
            <v-btn icon @click.stop="goToQuestion(index)">
              <v-icon color="blue lighten-1">mdi-send</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>

        <v-list-item v-for="(choice, index) in question.choices" :key="index">
          <v-list-item-content>
            <v-list-item-title v-text="choice.text"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-badge :content="choice.votes" color="blue" inline overlap>
              <v-icon color="blue">mdi-account</v-icon>
            </v-badge>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
    <EditQuestionForm
      :questions="questions"
      :mode="mode"
      :showDialog="dialog"
      @closeDialog="dialog=false"
    />
    <v-btn
      v-show="state === 'paused'"
      bottom
      color="pink"
      dark
      fab
      fixed
      right
      @click="createQuestion"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import EditQuestionForm from '@/components/moderator/EditQuestionForm.vue';

export default {
  components: {
    EditQuestionForm,
  },
  props: {
    sessionState: Object,
  },
  methods: {
    createQuestion() {
      this.mode.operation = 'create';
      this.mode.questionIndex = 0;
      this.dialog = true;
    },
    editQuestion(index) {
      this.mode.operation = 'edit';
      this.mode.questionIndex = index;
      this.dialog = true;
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },
    goToQuestion(index) {
      this.$socket.emit('goToQuestion', { questionId: index });
    },
    stopSession() {
      this.$socket.emit('stopSession');
    },
    createSession() {
      this.$socket.emit('createSession', {
        title: 'untitled session',
        questions: this.questions,
      });
    },
    switchState() {
      if (this.state === 'playing') {
        this.pause();
      } else {
        this.play();
      }
    },
    play() {
      this.createSession();
      this.state = 'playing';
    },
    pause() {
      this.stopSession();
      this.state = 'paused';
    },
  },
  computed: {
    stateIcon() {
      return this.state === 'paused' ? 'mdi-play' : 'mdi-stop';
    },
    stateDependentQuestions() {
      if (this.sessionState && this.sessionState.questions) {
        return this.sessionState.questions;
      }
      return this.questions;
    },
  },
  mounted() {
    console.log('mounted in questions list');
    const alias = this.$route.params.questionnaireAlias;
    fetch(`https://api.engage.avalia.io/questionnaires/${alias}`, {
      mode: 'cors',
    })
      .then(response => response.json())
      .then((questionnaire) => {
        this.questions.splice(0, this.questions.length);
        this.questions.push(
          ...questionnaire.questions.map(q => ({
            text: q.text,
            choices: q.choices, // q.choices.map(c => c.text),
          })),
        );
      });
  },
  data() {
    return {
      state: 'paused',
      dialog: false,
      mode: {
        operation: 'create',
        questionIndex: 0,
      },
      questions: [
        {
          text: 'How do you do?',
          choices: [
            {
              text: 'Great!',
              votes: 0,
            },
            {
              text: 'So, so...!',
              votes: 0,
            },
            {
              text: 'Bad :-(',
              votes: 0,
            },
          ],
        },
      ],
    };
  },
};
</script>
