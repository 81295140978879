<template>
  <v-app id="inspire">
    <v-content>
      <v-container class="do-not-fill-height" fluid justify="center">
        <v-row class="my-5" align="center" justify="center">
          <FeedbackCard v-if="sessionState" :connected="connected" :sessionState="sessionState"/>
        </v-row>
        <v-row class="my-5" align="center" justify="center">
          <QuestionsList :sessionState="sessionState"/>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import FeedbackCard from '@/components/moderator/FeedbackCard.vue';
import QuestionsList from '@/components/moderator/QuestionsList.vue';

export default {
  props: {
    source: String,
  },
  components: {
    FeedbackCard,
    QuestionsList,
  },
  data: () => ({
    connected: false,
    sessionState: undefined,
    dialog: false,
  }),
  methods: {
    createQuestion(question) {
      console.log(`create ${question}`);
    },
  },
  sockets: {
    connect() {
      console.log('Connected with server.');
      this.connected = true;
    },
    disconnect() {
      console.log('Disconnected from server...');
      this.connected = false;
    },
    reconnect() {
      console.log('Reconnected with server.');
      this.$socket.emit('joinSession', {
        nickname: 'ADMIN',
        sessionId: this.sessionState.sessionId,
      });
      this.connected = true;
    },
    sessionState(sessionState) {
      this.sessionState = sessionState;
    },
    sessionStopped() {
      this.sessionState = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
html, body {
  background-color: olive;
}
</style>
