<template>
  <div class="box">
    <div class="countdown">
      <span>{{ remainingTime }}</span>
    </div>
    <div class="question"> {{ sessionState.questions && sessionState.currentQuestionId !== -1 ? sessionState.questions[sessionState.currentQuestionId].text : 'waiting...' }}</div>
    <button v-for="(choice, index) in getChoices" :key='index' @click="submitAnswer(choice.text)" class="button">
      {{ choice.text }}
    </button>
  </div>
</template>

<script>

export default {
  props: {
    isConnected: Boolean,
    sessionState: Object,
    clientState: Object,
  },
  data() {
    return {
      nickname: '',
      roomId: '',
      remainingTime: 15,
      timerId: undefined,
    };
  },
  computed: {
    getChoices() {
      this.startCountdown(12);
      if (this.sessionState.currentQuestionId === -1) {
        return [];
      }
      return this.sessionState.questions ? this.sessionState.questions[this.sessionState.currentQuestionId].choices : [];
    },
  },
  methods: {
    startCountdown(seconds) {
      this.remainingTime = seconds;
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => {
        this.remainingTime -= 1;
        if (this.remainingTime <= 0) {
          clearInterval(this.timerId);
        }
      }, 1000);
    },
    submitAnswer(e) {
      this.$socket.emit('submitAnswer', {
        questionId: this.sessionState.currentQuestionId,
        answer: e,
      });
      this.clientState.lastAnsweredQuestionId = this.sessionState.currentQuestionId;
    },
  },
};
</script>

<style lang="scss" scoped>

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0px rgba(255, 0, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 85px rgba(255, 0, 255, 0);
  }
}

.countdown {
  display: flex;
  justify-content: center;

  span {
    animation: shadow-pulse 1s infinite;
    background: $avalia-magenta;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 1.1rem;
    font-weight: 900;
    border: none;
    border-radius: 1000px;
    padding: 10px;
  }
}

.v-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>>
