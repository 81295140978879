<template>
  <div class="box">
    <div v-show="shouldDisplayPause">
      <img class="cat" src="@/assets/img/sure-cat.gif">
    </div>
    <div v-show="shouldDisplayQuestion || shouldDisplayResponse" class="question">
      {{ question }}
    </div>
    <div v-show="!shouldDisplayPause" class="student">
      <div v-show="!shouldDisplayResponse && !responseSubmitted">
        <input v-model="studentResponseLocal" placeholder="Donne ta réponse">
        <button v-on:click="submitResponse">Valider</button>
      </div>
      <div class="confirmation" v-show="shouldDisplayQuestion && responseSubmitted">
        Tu as validé ta réponse: {{ studentResponse() }}
      </div>
    </div>
    <div v-show="shouldDisplayResponse" class="correctResponse">
      Réponse correcte: {{ response }}
    </div>
    <div v-show="shouldDisplayResponse" class="feedback">
      <div>
        <div class="feedbackClass" v-if="studentResponse().trim() !== ''">
          Ta réponse: {{ studentResponse() }}
        </div>
        <div class="warning" v-else>
          Tu n'as pas donné de réponse...
        </div>
      </div>
      <div :class="feedbackClass" class="feedback">
        {{ feedback }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sessionState: Object,
  },
  data() {
    return {
      studentResponseLocal: '',
      responseSubmitted: false,
      studentResponses: {},
    };
  },
  computed: {
    shouldDisplayPause() {
      return this.sessionState?.activity?.state === 'PAUSED';
    },
    shouldDisplayQuestion() {
      return this.sessionState?.activity?.state === 'COLLECTING';
    },
    shouldDisplayResponse() {
      return this.sessionState?.activity?.state === 'SHOWING_RESULTS';
    },
    question() {
      return this.sessionState?.activity?.question;
    },
    response() {
      return this.sessionState?.activity?.response;
    },
    feedback() {
      if (this.studentResponse().trim().toLowerCase() === this.response.trim().toLowerCase()) {
        return 'Bravo!';
      }
      return 'Dommage!';
    },
    feedbackClass() {
      if (this.studentResponse().trim().toLowerCase() === this.response.trim().toLowerCase()) {
        return 'correct';
      }
      if (this.studentResponse().trim() === '') {
        return 'warning';
      }
      return 'incorrect';
    },
  },
  watch: {
    question(newQuestion) {
      this.studentResponseLocal = '';
      const existingAnswer = this.studentResponses[newQuestion];
      if (existingAnswer === undefined || existingAnswer.trim() === '') {
        this.responseSubmitted = false;
      } else {
        this.responseSubmitted = true;
      }
    },
  },
  methods: {
    submitResponse() {
      if (this.studentResponseLocal.trim() === '') return;
      this.studentResponses[this.question] = this.studentResponseLocal;
      this.responseSubmitted = true;
    },
    studentResponse() {
      return this.studentResponses[this.question] || '';
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>

.box {

  .student {
    margin-top: 2rem;
    input {
      color: white;
      border-bottom: thin solid white;
      margin-right: 2rem;
      padding: 0.5rem;
    }

    input::placeholder {
        color: orange;
    }

    input:focus-visible {
      outline: none;
    }

    button {
      border: thin solid white;
      padding: 0.5rem;
      color: white;
    }

    .confirmation {
      font-size: 1.4rem;
      color: orange;
    }
  }

  .question {
    padding: 2rem;
    border: thin solid orange;
    font-size: 2rem;
    color: white;
  }

  .correctResponse {
    font-size: 2rem;
    color: white;
    text-align: center;
    margin-top: 2rem;
  }

  .feedback {
    margin-top: 2rem;
    font-size: 1.8rem;
    color: white;
    text-align: center;
  }

  .correct {
    color: greenyellow;
  }

  .incorrect {
    color: red;
  }

  .warning {
    color: orange;
  }

  .cat {
    max-height: 16rem;
  }

}
</style>
