<template>
  <v-card width="500" class="mx-auto" shaped color="#385F73" dark>
    <v-card-title class="headline">Live Session: {{ sessionState.sessionId }}</v-card-title>
    <v-card-subtitle>{{ sessionState.numberOfConnectedUsers }} user(s) connected; you are connected: {{ connected }}</v-card-subtitle>
    <v-card-text>
      <v-container>
        <v-row>
          <v-card outlined tile color="#385F73" dark>
            <div><b>State</b>: {{ sessionState.state }} </div>
            <div><b>Question {{ progress }}</b>: {{ currentQuestion }} </div>
            <div>
              <b>Answers</b>:
              <li v-for="(choice, index) in getChoices" :key='index'><b>{{ choice.text }}</b> : {{ choice.votes }} vote(s)</li>
            </div>
          </v-card>
        </v-row>
        <v-row class="mt-5" justify="center">
          <v-card justify="center">
            <v-btn-toggle
              color="pink"
              light
              v-model="selectedState"
              @change="stateChangeRequested"
              mandatory
            >
              <v-btn value="PAUSED">
                <span>Paused</span>
                <v-icon right>mdi-pause</v-icon>
              </v-btn>
              <v-btn value="VOTING">
                <span>Voting</span>
                <v-icon right>mdi-vote</v-icon>
              </v-btn>
              <v-btn value="REVIEWING">
                <span>Reviewing</span>
                <v-icon right>mdi-chat</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-card>
        </v-row>
        <v-row class="mt-5" justify="start">
            <v-text-field v-model="ciipDigitalResourceId" label="CIIP Resource ID"
          ></v-text-field>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="prevQuestion" text>Prev question</v-btn>
      <v-btn @click="nextQuestion" text>Next question</v-btn>
      <v-btn @click="showCiipDigitalResource" text>Show Resource</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    connected: Boolean,
    sessionState: Object,
  },
  data() {
    return {
      selectedState: undefined,
      ciipDigitalResourceId: undefined,
    };
  },
  computed: {
    currentQuestion() {
      if (this.sessionState.questions && this.sessionState.currentQuestionId >= 0 && this.sessionState.currentQuestionId < this.sessionState.questions.length) {
        return `${this.sessionState.questions[this.sessionState.currentQuestionId].text}`;
      }
      return 'oops';
    },
    progress() {
      if (this.sessionState.questions && this.sessionState.currentQuestionId >= 0 && this.sessionState.currentQuestionId < this.sessionState.questions.length) {
        return `${this.sessionState.currentQuestionId + 1} / ${this.sessionState.questions.length}`;
      }
      return '';
    },
    getChoices() {
      if (this.sessionState.currentQuestionId === -1) {
        return [];
      }
      return this.sessionState.questions ? this.sessionState.questions[this.sessionState.currentQuestionId].choices : [];
    },
  },
  methods: {
    showQuestion() {
      this.$socket.emit('showQuestion', {
      });
    },
    showResults() {
      this.$socket.emit('showResults', {
      });
    },
    pauseSession() {
      this.$socket.emit('pauseSession', {
      });
    },
    prevQuestion() {
      this.$socket.emit('prevQuestion', {
      });
    },
    nextQuestion() {
      this.$socket.emit('nextQuestion', {
      });
    },
    showCiipDigitalResource() {
      console.log(this.ciipDigitalResourceId);
      this.$socket.emit('CIIP.SHOW-DIGITAL-RESOURCE.DISPLAY', {
        ciipDigitalResourceId: this.ciipDigitalResourceId,
      });
    },
    stateChangeRequested(newState) {
      switch (newState) {
        case 'VOTING':
          this.showQuestion();
          break;
        case 'REVIEWING':
          this.showResults();
          break;
        case 'PAUSED':
          this.pauseSession();
          break;
        default:
      }
    },
  },
};
</script>>

<style lang="scss" scoped>

</style>>
