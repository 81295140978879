import Vue from 'vue';
import VueSocketIO from 'vue-socket.io';
import VueRouter from 'vue-router';
import Switcher from '@/pages/MultiAppSwitcher.vue';
import './registerServiceWorker';
import '@/assets/css/styles.scss';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.use(new VueSocketIO({
  debug: false,
  connection: 'https://engage.ciip.ch',
  // connection: 'https://classroom.ciip.ch:1443',
  /*
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_',
  },
  */
  options: { path: '/api/socket.io' }, // Optional options
}));

Vue.use(VueRouter);

Vue.config.productionTip = false;

new Vue({
  render: h => h(Switcher),
  vuetify,
  router,
}).$mount('#VotingApp');
