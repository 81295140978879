<template>
  <div>
    <div class="waiting">
      <component :is="whichLoader" v-bind="loaderProps"></component>
    </div>
    <div class="question"> {{ message }}</div>
    <div v-if="isConnected" class="question"> {{ votingStats.numberOfVotes }} / {{ votingStats.numberOfUsers }}</div>
  </div>
</template>

<script>
import Loader from 'vue-spinner/src/DotLoader.vue';
import RiseLoader from 'vue-spinner/src/RiseLoader.vue';
import RotateLoader from 'vue-spinner/src/RotateLoader.vue';

export default {
  props: {
    isConnected: Boolean,
    message: String,
    sessionState: Object,
    votingStats: Object,
  },
  components: {
    Loader,
    RiseLoader,
    RotateLoader,
  },
  data() {
    return {
      whichLoader: RotateLoader,
      loaderProps: {
        color: 'magenta',
      },
    };
  },
};
</script>

<style lang="scss" scoped>

.waiting {
  // display: flex;
  justify-content: space-evenly;
  //border: thin solid yellow;
  filter: blur(0px);

  span {
    background: magenta;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    font-size: 1.8rem;
    font-weight: 900;
    border: thin solid;
    border-radius: 1000px;
    padding: 10px;
  }
}

.v-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
