<template>
  <div class="box">
    <div v-show="!shouldDisplayWord && !shouldDisplayRule">
      <img src="@/assets/img/drum-roll.gif">
    </div>
    <div v-show="shouldDisplayWord" class="word">
      {{ word }}
    </div>
    <div v-show="shouldDisplayRule" class="rule">
      {{ rule }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sessionState: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    shouldDisplayWord() {
      return this.sessionState?.activity?.state === 'SHOW_RULE' || this.sessionState?.activity?.state === 'SHOW_WORD';
    },
    shouldDisplayRule() {
      return this.sessionState?.activity?.state === 'SHOW_RULE';
    },
    word() {
      return this.sessionState?.activity?.word;
    },
    rule() {
      return this.sessionState?.activity?.rule;
    },
  },
  methods: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>

.word {
  font-size: 2rem;
  color: white;
}

.rule {
  color: darkorange;
  text-align: center;
  margin-top: 2rem;
}
</style>
