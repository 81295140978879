<template>
  <v-dialog :value="showDialog" :persistent="true" width="800px">
    <!--
    <template v-slot:activator="{ on }">
      <v-btn bottom color="pink" dark fab fixed right v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    -->
    <v-card v-if="question != undefined">
      <v-card-title class="white--text pink">{{ cartTitle }}</v-card-title>
      <v-container>
        <v-row class="mx-2">
          <v-col class="align-center justify-space-between" cols="12"></v-col>
          <v-col cols="12">
            <v-text-field
              prepend-icon="mdi-comment-question-outline"
              placeholder="Enter your question here..."
              v-model="question.text"
              ref="questionField"
              @keyup.enter="addChoice"
            />
          </v-col>
          <v-col ref="choicesParent" cols="12">
            <v-text-field
              prepend-icon="mdi-comment-account-outline"
              v-for="(choice, index) in question.choices"
              :key="index"
              v-model="question.choices[index].text"
              placeholder="Enter choice..."
              @keyup.enter="addChoice"
            >
              <template slot="append-outer">
                <v-btn text icon color="red" @click="removeChoice(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn text @click="addChoice" color="primary">Add choice</v-btn>
        <v-spacer />
        <v-btn text color="primary" @click="closeDialog()">Cancel</v-btn>
        <v-btn
          text
          @click="saveQuestion(question); closeDialog();"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    showDialog: Boolean,
    mode: Object,
    questions: Array,
  },
  data: () => ({
    question: {},
  }),
  mounted() {
    this.resetQuestion();
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.$nextTick().then(() => {
          this.$refs.questionField.focus();
        });
      }
    },
    mode: {
      deep: true,
      handler() {
        switch (this.mode.operation) {
          case 'create':
            this.resetQuestion();
            break;
          case 'edit':
            this.question = this.questions[this.mode.questionIndex];
            break;
          default:
        }
      },
    },
  },
  computed: {
    cartTitle() {
      switch (this.mode.operation) {
        case 'create':
          return 'Create question';
        case 'edit':
          return 'Edit question';
        default:
          return 'Question';
      }
    },
  },
  methods: {
    addChoice() {
      this.question.choices.push({
        text: '',
        votes: 0,
      });
      this.$nextTick(() => {
        console.log(this);
        console.log(this.$refs);
        this.$refs.choicesParent.children[this.$refs.choicesParent.children.length - 1].querySelector('input').focus();
      });
    },
    removeChoice(index) {
      this.question.choices.splice(index, 1);
    },
    resetQuestion() {
      this.question = {
        text: '',
        choices: [],
      };
    },
    saveQuestion() {
      if (this.mode.operation === 'create') {
        this.questions.push(this.question);
        this.resetQuestion();
        // this.$emit('questionCreated', q);
      }
    },
    closeDialog() {
      this.$emit('closeDialog');
    },

  },
};
</script>

<style></style>
