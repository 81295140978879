<template>
  <div class="box show-result">
    <div class="question"> {{ sessionState.questions && sessionState.currentQuestionId !== -1 ? sessionState.questions[sessionState.currentQuestionId].text : 'waiting...' }}</div>
    <div v-for="(choice, index) in getChoices" :key='index' class="result">
      <b>{{ choice.text }}</b> : {{ choice.votes }} votes
    </div>
  </div>
</template>

<script>

export default {
  props: {
    isConnected: Boolean,
    sessionState: Object,
    clientState: Object,
  },
  data() {
    return {
      nickname: '',
      roomId: '',
    };
  },
  computed: {
    getChoices() {
      if (this.sessionState.currentQuestionId === -1) {
        return [];
      }
      return this.sessionState.questions ? this.sessionState.questions[this.sessionState.currentQuestionId].choices : [];
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.show-result {
  .result {
    text-align: center;
    border: thin solid $avalia-orange;
    background-color: $avalia-orange;
    color: black;
    margin: 16px;
    padding: 16px;

  }

  .question {
    color: orange;
  }
}

</style>>
