/* eslint-disable global-require */
<template>
  <div class="app">
    <div class="column">
      <!-- <img class="connection" :src="connectionIcon" /> -->
      <component :is="currentFrame" :sessionState="sessionState" :clientState="clientState" :votingStats="votingStats" v-bind="currentFrameProperties"></component>
      <div class="footer">
        <img class="logo" src="@/assets/img/logo-ciip.png" />
        <div class="version">v0.2.4</div>
      </div>
    </div>
  </div>
</template>

<script>
import JoinRoom from '@/components/mobile/JoinRoom.vue';
import WaitingFrame from '@/components/mobile/WaitingFrame.vue';
import ShowQuestionFrame from '@/components/mobile/ShowQuestionFrame.vue';
import ShowResultsFrame from '@/components/mobile/ShowResultsFrame.vue';
import CiipResourceFrame from '@/components/mobile/CiipResourceFrame.vue';
import CiipPiocheFrame from '@/components/mobile/CiipPiocheFrame.vue';
import CiipQuizFrame from '@/components/mobile/CiipQuizFrame.vue';

export default {
  components: {
    JoinRoom,
    WaitingFrame,
    ShowQuestionFrame,
  },
  data() {
    return {
      title: '0',
      connected: false,
      sessionState: {
        numberOfConnectedUsers: 0,
        currentQuestionId: -1,
        questions: [],
      },
      clientState: {
        pseudo: undefined,
        sessionId: undefined,
        clientId: -1,
        lastAnsweredQuestionId: undefined,
      },
    };
  },
  computed: {
    connectionIcon() {
      // eslint-disable-next-line global-require
      return this.connected ? require('@/assets/img/icons8-connected-50.png') : require('@/assets/img/icons8-disconnected-50.png');
    },
    votingStats() {
      if (!this.sessionState.questions || this.sessionState.questions.length === 0) {
        return undefined;
      }
      const currentQuestion = this.sessionState.questions[this.sessionState.currentQuestionId];
      if (this.sessionState.currentQuestionId !== -1) {
        return {
          numberOfVotes: currentQuestion.choices?.reduce((sum, choice) => sum + choice.votes, 0),
          numberOfUsers: this.sessionState.numberOfConnectedUsers,
        };
      }
      return {
        numberOfVotes: 0,
        numberOfUsers: this.sessionState.numberOfConnectedUsers,
      };
    },
    currentFrame() {
      console.log(this.sessionState);
      if (this.clientState.clientId === -1) {
        return JoinRoom;
      }
      if (!this.connected) {
        return WaitingFrame;
      }
      if (this.sessionState.activity?.activityType === 'CIIP.RESOURCE_BEAMER') {
        return CiipResourceFrame;
      }
      if (this.sessionState.activity?.activityType === 'CIIP.PIOCHE') {
        return CiipPiocheFrame;
      }
      if (this.sessionState.activity?.activityType === 'CIIP.QUIZ') {
        return CiipQuizFrame;
      }
      if (this.sessionState.state === 'PAUSED') {
        return WaitingFrame;
      }
      if (this.sessionState.state === 'REVIEWING') {
        return ShowResultsFrame;
      }
      if (this.clientState.lastAnsweredQuestionId === this.sessionState.currentQuestionId) {
        return WaitingFrame;
      }
      if (this.sessionState.connectedClientIds[this.clientState.clientId].find(c => c === this.sessionState.currentQuestionId) !== undefined) {
        return WaitingFrame;
      }

      return ShowQuestionFrame;
    },
    currentFrameProperties() {
      if (this.clientState.clientId === -1) {
        return {};
      }
      if (!this.connected) {
        return { message: 'Contacting server...' };
      }
      if (this.sessionState.state === 'PAUSED') {
        return { message: 'Please wait...' };
      }
      if (this.clientState.lastAnsweredQuestionId === this.sessionState.currentQuestionId) {
        return { message: 'Please wait for other people to vote...' };
      }
      if (this.sessionState.connectedClientIds[this.clientState.clientId].find(c => c === this.sessionState.currentQuestionId) !== undefined) {
        return { message: 'Please wait for other people to vote...' };
      }
      return {};
    },
  },
  sockets: {
    connect() {
      console.log('connected');
      this.connected = true;
      console.log(this.$socket);
    },
    disconnect() {
      console.log('disconnected');
      this.title = 'ooops';
      this.connected = false;
      console.log(this.$socket);
    },
    reconnect() {
      console.log('reconnected');
      this.title = 'reconnected';
      this.connected = true;
      this.$socket.emit('joinSession', {
        nickname: this.clientState.nickname,
        sessionId: this.clientState.sessionId,
      });
      console.log(this.$socket);
    },
    sessionStart(sessionStart) {
      this.clientState.clientId = sessionStart.clientId;
    },
    sessionStopped() {
      console.log('session stopped');
      localStorage.removeItem('avalia.engage.nickname');
      localStorage.removeItem('avalia.engage.sessionId');

      this.sessionState.currentQuestionId = -1;
      this.sessionState.questions = [];
      this.clientState.clientId = -1;
      this.clientState.lastAnsweredQuestionId = undefined;
    },
    sessionState(sessionState) {
      this.sessionState = sessionState;
      this.title = sessionState.numberOfConnectedUsers;
    },
  },
};
</script>

<style lang="scss">

html,
body {
  width: 100%;
  // height: 100%;
  // height: calc(100% + 172px);
  margin: 0px;
  // position: fixed;
  // top: -172px;
  // bottom: 100px;
  // border: thin solid yellow;

  overflow: scroll;
  background-color: $color-background;
  overscroll-behavior: none;
  // font-family: 'Courier New', Courier, monospace;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
}
</style>

<style lang="scss" scoped>

.app {
  font-family: "Open Sans", sans-serif;
  min-height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
}

::v-deep .column {
  flex-grow: 1;
  // min-height: 100%;
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $color-background;
  color: magenta;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .logo {
    //filter: invert(0);
    // position: relative;
    // bottom: 16px;
    // top: 32px;
    // left: 0;
    // right: 0;
    // margin: auto;
  }

  .version {
    margin-left: 1rem;
    color: white;
  }
}

.connection {
  filter: invert(1);
  // position: relative;
  width: 32px;
  margin-bottom: 32px;
  // top: 16px;
  // left: 0;
  // right: 0;
  // margin: auto;
}

::v-deep  .question {
  flex-shrink: 1;
  text-align: center;
  margin: 16px;
  padding: 16px;
  font-size: 1.4rem;
  //background-color: magenta;
  color: magenta;
  font-weight: bolder;
}

::v-deep  .box {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

::v-deep .avaInput {
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  color: magenta;
  background: #222222;
  border: none;
  border-bottom: thin solid gray;
  margin: 16px;
  padding: 16px;

  &::placeholder {
    color: gray;
  }

  &:required:valid {
    color: greenyellow;
    border-bottom: thin solid greenyellow;
  }

  &:focus {
    outline: 0;
    color: white;
    border-bottom: thin solid white;
  }

  &:focus::placeholder {
    color: white;
  }
}

::v-deep .button {
  flex-shrink: 1;
  //font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;

  background-color: transparent;
  text-align: center;
  text-decoration: none;
  margin: 16px;
  padding: 16px;
  border: thin solid;
  color: magenta;

  &:focus {
    outline: 0;
    color: greenyellow;
    border-color: greenyellow;
    cursor: pointer;
  }

  &:hover {
    color: greenyellow;
    border-color: greenyellow;
    cursor: pointer;
  }

  &:active {
    border-color: magenta;
    background-color: magenta;
    color: black;
  }
}
</style>
